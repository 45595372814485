export function statusVariant() {
    return e => e ? 'success' : 'danger';
}
export function statusLabel() {
    return e => e ? 'فعال' : 'غیر فعال';
}

export function enumLabel() {
    const a = {
        FINISHED: 'تکمیل شده',
        IS_OPEN: 'باز',
        CANCELLED_BY_ADMIN: 'لغو توسط مدیر',
        CANCELLED_BY_USER: 'لغو توسط کاربر',
        EXPIRED: 'منضی شده',
        RAISED_BY_USER: 'درحال بررسی',
        PENDING: 'درحال انجام',
        ACCEPTED: 'موفق'
    }
    return e => a[e]
}

export function enumColor() {
    const a = {
        FINISHED: 'var(--main-green)',
        IS_OPEN: 'var(--main-green)',
        CANCELLED_BY_ADMIN: 'var(--main-red)',
        CANCELLED_BY_USER: 'var(--main-red)',
        EXPIRED: 'darkgoldenrod',
        RAISED_BY_USER: 'darkorange',
        PENDING: 'darkgoldenrod',
        ACCEPTED: 'var(--main-green)'
    }
    return e => a[e]
}